<template>
  <div class="search-user">
    <v-card elevation="0">
      <v-card-title>{{ $t("__searchuser_title") }}</v-card-title>
      <v-card-text>
        <v-radio-group row v-model="searchMethod">
          <v-radio :label="$t('__email')" value="email"></v-radio>
          <v-radio :label="$t('__user_id')" value="id"></v-radio>
          <v-radio :label="$t('__phone_number')" value="phone"></v-radio>
        </v-radio-group>
        <v-text-field
          class="pt-0"
          clearable
          :rules="[rules.required, rules.email]"
          :label="$t('__email')"
          v-model="input.email"
          v-if="searchMethod === 'email'"
        >
        </v-text-field>
        <v-text-field
          class="pt-0"
          clearable
          counter
          maxlength="28"
          :rules="[rules.required, rules.counter, rules.numAndEg]"
          :label="$t('__user_id')"
          v-model="input.id"
          v-if="searchMethod === 'id'"
        >
        </v-text-field>
        <PhoneNumberInput
          :label="$t('__phone_number')"
          :required="true"
          v-model="input.phoneNum"
          v-if="searchMethod === 'phone'"
        />
        <p class="error--text mb-0" v-if="error">
          {{ $t("__user_data_invalid") }}
        </p>
      </v-card-text>
      <v-card-actions class="pl-4 pt-0">
        <v-btn color="primary" elevation="2" @click="getUserData">
          {{ $t("__confirm") }}
        </v-btn>
        <v-btn elevation="2" @click="clearInput">
          {{ $t("__cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card elevation="0" v-if="user">
      <v-card-text class="pb-0">
        <table class="data-table">
          <tr>
            <th>ID</th>
            <td v-if="!isMobile">{{ userID }}</td>
          </tr>
          <tr>
            <td class="data-table__subrow_td" v-if="isMobile">
              {{ userID }}
            </td>
          </tr>
          <template v-for="(item, i) in items">
            <tr :key="`data-${i}`">
              <th>{{ item.text }}</th>
              <td v-if="!isMobile">
                <template>
                  {{ user[item.key] }}
                  <v-icon
                    v-if="user[item.key] && item.action"
                    color="primary"
                    @click="item.action"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
              </td>
            </tr>
            <tr v-if="isMobile" :key="`data-${i}-1`">
              <td class="data-table__subrow_td">
                <template v-if="user">
                  {{ user[item.key] }}
                  <v-icon
                    v-if="user[item.key] && item.action"
                    color="primary"
                    @click="item.action"
                  >
                    mdi-open-in-new
                  </v-icon>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </v-card-text>
      <v-card-actions class="pl-4">
        <v-btn color="primary" elevation="2" @click="toUserInfo">{{
          $t("__more_info")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { adminAPI } from "@/plugins/service";
import { format } from "date-fns";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";

export default {
  name: "SearchUser",
  computed: {
    ...mapGetters({
      isMobile: "isMobile"
    })
  },
  components: {
    PhoneNumberInput
  },
  data() {
    return {
      userID: "",
      user: null,
      error: false,
      input: {
        email: "",
        id: "",
        phoneNum: ""
      },
      searchMethod: "email",
      rules: {
        required: value => !!value || this.$t("__required"),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("__email_address_invalid");
        },
        counter: value => value.length == 28 || this.$t("__ID_invalid"),
        numAndEg: value => {
          const pattern = /^([a-zA-Z]+\d+|\d+[a-zA-Z]+)[a-zA-Z0-9]*$/;
          return pattern.test(value) || this.$t("__ID_invalid");
        }
      },
      items: [
        {
          text: this.$t("__first_name"),
          key: "first_name"
        },
        {
          text: this.$t("__last_name"),
          key: "last_name"
        },
        {
          text: this.$t("__email"),
          key: "email"
        },
        {
          text: this.$t("__phone_number"),
          key: "phone_number"
        },
        {
          text: this.$t("__join_time"),
          key: "join_time_f"
        },
        {
          text: this.$t("__update_time"),
          key: "update_time_f"
        }
      ]
    };
  },
  methods: {
    getUserData() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.getUser(
            idToken,
            this.input.id,
            this.input.email,
            this.input.phoneNum
          );
        })
        .then(result => {
          this.$store.dispatch("setIsLoading", false);
          this.userID = result.user_id;
          this.user = result["user"];
          this.user["join_time_f"] = this.formatTime(this.user["join_time"]);
          this.user["update_time_f"] = this.formatTime(
            this.user["update_time"]
          );
          this.error = false;
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
          this.error = true;
        });
    },
    clearInput() {
      this.input = {
        email: "",
        id: "",
        phoneNum: ""
      };
      this.error = false;
    },
    formatTime(time) {
      if (time === "0001-01-01T00:00:00Z") return "-";
      return format(new Date(time), "yyyy/MM/dd HH:mm:ss");
    },
    toUserInfo() {
      this.$router.push(`/user-info/${this.userID}`);
    }
  }
};
</script>
<style lang="scss">
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-block: 1em;

  th,
  td {
    border: 1px solid silver;
    padding: 8px;
  }
  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    color: black;
  }
  td {
    color: black;
  }
  &__subrow_td {
    padding-left: 24px !important;
  }
}
</style>
